/**
 * Formats a person's name by concatenating the first, middle, and last names.
 * If all three names are null or undefined, returns null.
 *
 * @param firstName
 * @param middleName
 * @param lastName
 */
export function formatPersonName(
  firstName?: string | null,
  middleName?: string | null,
  lastName?: string | null,
): string | null {
  const parts: string[] = [];

  if (firstName) {
    parts.push(firstName);
  }

  if (middleName) {
    parts.push(middleName);
  }

  if (lastName) {
    parts.push(lastName);
  }

  if (parts.length > 0) {
    return parts.join(' ');
  }

  return null;
}

export function stringElseNull(value: unknown): string | null {
  if (typeof value === 'string') {
    return value;
  }
  return null;
}

/**
 * Creates a string from all the elements separated using `separator` and using
 * the given `prefix` and `postfix` if supplied.
 *
 * If the collection could be huge, you can specify a non-negative value of
 * `limit`, in which case only the first `limit` elements will be appended,
 * followed by the `truncated` string (which defaults to "...").
 */
export function joinStrings(
  strings: string[],
  {
    separator = ', ',
    prefix = '',
    postfix = '',
    limit = -1,
    truncated = '...',
  }: {
    separator?: string;
    prefix?: string;
    postfix?: string;
    limit?: number;
    truncated?: string;
  },
): string {
  let buffer = prefix;
  let count = 0;

  for (const element of strings) {
    if (++count > 1) {
      buffer += separator;
    }

    if (limit < 0 || count <= limit) {
      buffer += element;
    } else {
      break;
    }
  }

  if (limit >= 0 && count > limit) {
    buffer += truncated;
  }

  buffer += postfix;
  return buffer;
}

const ALPHANUMERICS =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

/**
 * Generates a random alphanumeric string of the specified length using the provided characters.
 */
export function randomAlphanumeric(length: number) {
  let result = '';

  for (let i = 0; i < length; i++) {
    result += ALPHANUMERICS[Math.floor(Math.random() * ALPHANUMERICS.length)];
  }

  return result;
}

// TODO: This is neither a generalized function nor a specific function for
// credentialing. Update its call-sites for a better structure.
export function capitalizeAndFormat(word: string | undefined | null) {
  if (!word) return 'N/A';

  if (word === 'TERMINATE') {
    return 'Terminated';
  }

  if (word === 'SUCCESSFUL_REQUEST') {
    return 'Credentialed';
  }

  return word
    .split(/[_\s]+/)
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
    .join(' ');
}

export const US_ZIP_CODE_REGEX = /^\d{5}([- ]?\d{4})?$/;

const uuidRegex =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export function isValidUUID(uuid: string): boolean {
  return uuidRegex.test(uuid);
}
