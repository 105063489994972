import { Navigate, RouteObject } from 'react-router-dom';

import ProtectedRoute from '@/components/auth/protected-route';

import { UserType } from '@/lib/user/type';

import { ConfigurationsRoutes } from './configurations/routes';
import { UsersRoutes } from './users/routes';

export const SuperadminRoutes: RouteObject[] = [
  {
    path: '/superadmin',
    lazy: async () => {
      const { SuperadminLayout } = await import('./layout');
      return {
        element: (
          <ProtectedRoute userType={UserType.Superadmin}>
            <SuperadminLayout />
          </ProtectedRoute>
        ),
      };
    },
    children: [
      {
        index: true,
        element: <Navigate to="/superadmin/users" replace />,
      },
      UsersRoutes,
      ConfigurationsRoutes,
    ],
  },
];
