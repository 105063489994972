import { Navigate, RouteObject } from 'react-router-dom';

import { FacilityProfileRoutes } from './profile/routes';

export const FacilityDetailsRoutes: RouteObject = {
  path: ':facilityId',
  lazy: async () => {
    const { FacilityDetailsLayout } = await import('./layout');
    return { element: <FacilityDetailsLayout /> };
  },
  children: [
    {
      index: true,
      element: <Navigate to="overview" replace />,
    },
    {
      path: 'overview',
      lazy: async () => {
        const { OverviewPage } = await import('./overview/page');
        return { element: <OverviewPage /> };
      },
    },
    {
      path: 'tasks',
      lazy: async () => {
        const { TasksPage } = await import('./tasks/page');
        return { element: <TasksPage /> };
      },
    },
    FacilityProfileRoutes,
    {
      path: 'ongoing-monitoring',
      lazy: async () => {
        const { OngoingMonitoringLayout } = await import(
          './ongoing-monitoring/layout'
        );
        return { element: <OngoingMonitoringLayout /> };
      },
      children: [
        {
          index: true,
          element: <Navigate to="overview" replace />,
        },
        {
          path: 'overview',
          lazy: async () => {
            const { OngoingMonitoringOverviewPage } = await import(
              './ongoing-monitoring/overview/page'
            );
            return { element: <OngoingMonitoringOverviewPage /> };
          },
        },
        {
          path: ':eventType',
          lazy: async () => {
            const { VerificationPage } = await import(
              './ongoing-monitoring/$eventType/page'
            );
            return { element: <VerificationPage /> };
          },
        },
      ],
    },
  ],
};
