import { RouteObject } from 'react-router-dom';

import { FacilityDetailsRoutes } from './$facilityId/routes';
import { CreateFacilityRoutes } from './create/routes';

export const FacilitiesRoutes: RouteObject = {
  path: 'facilities',
  children: [
    {
      index: true,
      lazy: async () => {
        const { FacilityListPage } = await import('./page');
        return { element: <FacilityListPage /> };
      },
    },
    FacilityDetailsRoutes,
    CreateFacilityRoutes,
  ],
};
