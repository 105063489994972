import createFetchClient, {
  Client as FetchClient,
  Middleware,
} from 'openapi-fetch';
import createClient, { OpenapiQueryClient } from 'openapi-react-query';
import { HttpMethod } from 'openapi-typescript-helpers';

import { useSessionStore } from '../auth/session-store';
import { HTTPError } from './http-error';
import type { paths } from './openapi';

const backendHost =
  import.meta.env.VITE_BACKEND_HOST || 'staging-backend.withassured.com';
export const baseAPIUrl =
  import.meta.env.VITE_NO_HTTPS === 'true'
    ? `http://${backendHost}`
    : `https://${backendHost}`;

const authMiddleware: Middleware = {
  async onRequest({ request }) {
    const token = useSessionStore.getState().session?.accessToken.token;
    if (token) {
      request.headers.set('Authorization', `Bearer ${token}`);
    }
    return request;
  },
};

const throwOnError: Middleware = {
  async onResponse({ response }) {
    if (!response.ok) {
      const body = response.headers.get('content-type')?.includes('json')
        ? await response.clone().json()
        : await response.clone().text();
      throw new HTTPError(body);
    }
    return undefined;
  },
};

export const fetchClient = createFetchClient<paths>({
  baseUrl: baseAPIUrl,
});
fetchClient.use(authMiddleware);
fetchClient.use(throwOnError);

type GetQueryKeyFunction = <
  Method extends HttpMethod,
  Path extends keyof paths & string,
>(
  method: Method,
  url: Path,
) => [Method, Path];

// Create a wrapper function to extend the original createClient
function createExtendedClient(
  client: FetchClient<paths>,
): OpenapiQueryClient<paths> & { getQueryKey: GetQueryKeyFunction } {
  const originalClient = createClient(client);

  const getQueryKey: GetQueryKeyFunction = (method, url) => [method, url];

  return {
    ...originalClient,
    getQueryKey,
  };
}

export const $api = createExtendedClient(fetchClient);
