import { Navigate, RouteObject } from 'react-router-dom';

export const PacketRoutes: RouteObject = {
  path: 'facility/:requestId',
  lazy: async () => {
    const { PacketLayout } = await import('./layout');
    return { element: <PacketLayout /> };
  },
  children: [
    {
      index: true,
      element: <Navigate to="packet-overview" replace />,
    },
    {
      path: 'packet-overview',
      lazy: async () => {
        const { PacketOverview } = await import('./packet-overview/page');
        return { element: <PacketOverview /> };
      },
    },
    {
      path: ':eventType',
      lazy: async () => {
        const { EventsPage } = await import('./$eventType/page');
        return { element: <EventsPage /> };
      },
    },
  ],
};
