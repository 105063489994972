import { jwtDecode, JwtPayload } from 'jwt-decode';

import { components } from '../api/openapi';
import { dtoToUserType } from '../user/type';
import { SessionState } from './session-store';

const fallbackAccessTokenValidity = 2 * 60 * 60; // 2 hours
const fallbackRefreshTokenValidity = 15 * 24 * 60 * 60; // 15 days

const sessionValidityPadding = 1 * 60; // 1 minute

export function getAccessTokenValidity(payload: JwtPayload): number {
  const fallbackExpiry = Date.now() / 1000 + fallbackAccessTokenValidity;
  return (payload.exp ?? fallbackExpiry) - sessionValidityPadding;
}

export function getRefreshTokenValidity(payload: JwtPayload): number {
  const fallbackExpiry = Date.now() / 1000 + fallbackRefreshTokenValidity;
  return (payload.exp ?? fallbackExpiry) - sessionValidityPadding;
}

function parseProviderInfo(resp: components['schemas']['SWLoginResponse']): {
  id: string;
  minCompleted: boolean;
} | null {
  const provider = {
    id: resp?.extra_data?.provider_profile_id,
    minCompleted: resp?.extra_data?.min_profile_completed,
  };

  if (provider.id == null || provider.minCompleted == null) {
    return null;
  }
  return provider;
}

function parseClientInfo(resp: components['schemas']['SWLoginResponse']): {
  id: string;
  taxEntityExists: boolean;
} | null {
  const client = {
    id: resp?.extra_data?.client_details?.id,
    taxEntityExists: resp?.extra_data?.tax_entity_exists,
  };

  if (client.id == null || client.taxEntityExists == null) {
    return null;
  }
  return {
    id: client.id,
    taxEntityExists: client.taxEntityExists,
  };
}

export function parseLoginResponse(
  resp: components['schemas']['SWLoginResponse'],
): SessionState {
  const userId = resp.data.id;
  if (!userId) {
    throw new Error('Invalid user ID');
  }

  const userType = dtoToUserType(resp.data.user_type);
  if (!userType) {
    throw new Error('Invalid user type');
  }

  const accessToken: string = resp.data.jwt.access;
  const accessPayload = jwtDecode(accessToken);

  const refreshToken: string = resp.data.jwt.refresh;
  const refreshPayload = jwtDecode(refreshToken);

  return {
    user: {
      id: userId,
      type: userType,
    },
    session: {
      accessToken: {
        token: accessToken,
        expiration: getAccessTokenValidity(accessPayload),
        issuedAt: accessPayload.iat ?? Date.now() / 1000,
      },
      refreshToken: {
        token: refreshToken,
        expiration: getRefreshTokenValidity(refreshPayload),
        issuedAt: refreshPayload.iat ?? Date.now() / 1000,
      },
    },
    provider: parseProviderInfo(resp),
    client: parseClientInfo(resp),
  };
}
