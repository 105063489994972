import { Navigate, RouteObject } from 'react-router-dom';

export const ProviderPacketRoutes: RouteObject = {
  path: 'provider/:requestId',
  lazy: async () => {
    const { CredentialingPacketLayout } = await import('./layout');
    return { element: <CredentialingPacketLayout /> };
  },
  children: [
    {
      index: true,
      element: <Navigate to="packet-overview" replace />,
    },
    {
      path: 'packet-overview',
      lazy: async () => {
        const { PacketOverview } = await import('./packet-overview/page');
        return { element: <PacketOverview /> };
      },
    },
    {
      path: ':eventType',
      lazy: async () => {
        const { GeneralEventsPage } = await import('./$eventType/page');
        return { element: <GeneralEventsPage /> };
      },
    },
    {
      path: 'disclosure',
      lazy: async () => {
        const { Disclosure } = await import('./disclosure/page');
        return { element: <Disclosure /> };
      },
    },
  ],
};
