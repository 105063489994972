import { Navigate, RouteObject } from 'react-router-dom';

export const UsersRoutes: RouteObject = {
  path: 'users',
  lazy: async () => {
    const { UsersLayout } = await import('./layout');
    return { element: <UsersLayout /> };
  },
  children: [
    {
      index: true,
      element: <Navigate to="active" replace />,
    },
    {
      path: 'active',
      lazy: async () => {
        const { ActiveUsers } = await import('./active/page');
        return { element: <ActiveUsers /> };
      },
    },
    {
      path: 'deactivated',
      lazy: async () => {
        const { DeactivatedUsers } = await import('./deactivated/page');
        return { element: <DeactivatedUsers /> };
      },
    },
    {
      path: 'invited',
      lazy: async () => {
        const { InvitedUsers } = await import('./invited/page');
        return { element: <InvitedUsers /> };
      },
    },
  ],
};
