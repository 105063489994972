import { Navigate, RouteObject } from 'react-router-dom';

export const ConfigurationsRoutes: RouteObject = {
  path: 'configurations',
  children: [
    {
      index: true,
      element: <Navigate to="psv-events" replace />,
    },
    {
      path: 'psv-events',
      lazy: async () => {
        const { PSVEventsPage } = await import('./psv-events/page');
        return { element: <PSVEventsPage /> };
      },
    },
    {
      path: 'psv-sources',
      lazy: async () => {
        const { PSVSourcesPage } = await import('./psv-sources/page');
        return { element: <PSVSourcesPage /> };
      },
    },
    {
      path: 'health-plans',
      lazy: async () => {
        const { HealthPlansPage } = await import('./health-plans/page');
        return { element: <HealthPlansPage /> };
      },
    },
  ],
};
