import { RouteObject } from 'react-router-dom';

import { FacilityPacketRoutes } from './$requestId/routes';

export const FacilityRoutes: RouteObject[] = [
  {
    path: 'facility',
    lazy: async () => {
      const { AdminCredentialingPage } = await import('./page');
      return { element: <AdminCredentialingPage /> };
    },
  },
  FacilityPacketRoutes,
];
