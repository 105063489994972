import { Navigate, RouteObject, useParams } from 'react-router-dom';

import { missingPathParam, notFound } from '@/lib/route-errors';
import { isValidUUID } from '@/lib/string-utils';

import { FacilityRoutes } from './facility/routes';
import { ProviderRoutes } from './provider/routes';

function BackwardsCompatibilityReRoute() {
  const { requestId, subroute } = useParams();
  console.log('requestId', requestId);
  console.log('subroute', subroute);

  if (!requestId) {
    missingPathParam('requestId');
  }

  if (!isValidUUID(requestId)) {
    console.error('Invalid requestId');
    notFound();
  }

  if (!subroute) {
    return <Navigate to={`/admin/credentialing/provider/${requestId}`} />;
  }

  return (
    <Navigate to={`/admin/credentialing/provider/${requestId}/${subroute}`} />
  );
}

export const CredentialingRoutes: RouteObject = {
  path: 'credentialing',
  children: [
    {
      index: true,
      element: <Navigate to="provider" replace />,
    },
    ...ProviderRoutes,
    ...FacilityRoutes,

    // Backwards compatibility routes
    {
      path: ':requestId',
      lazy: async () => {
        return {
          element: <BackwardsCompatibilityReRoute />,
        };
      },
    },
    {
      path: ':requestId/:subroute',
      lazy: async () => {
        return {
          element: <BackwardsCompatibilityReRoute />,
        };
      },
    },
  ],
};
