import { UNSAFE_ErrorResponseImpl } from 'react-router-dom';

export function notFound(statusText: string = 'Not Found'): never {
  throw new UNSAFE_ErrorResponseImpl(404, statusText, null);
}

export function missingPathParam(
  param: string,
  statusText: string = `Missing ${param} in ${window.location.href}`,
): never {
  throw new UNSAFE_ErrorResponseImpl(400, statusText, null);
}
