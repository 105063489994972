import { RouteObject } from 'react-router-dom';

import { CredentialingPacketRoutes } from './$requestId/routes';

export const ProviderRoutes: RouteObject[] = [
  {
    path: 'provider',
    lazy: async () => {
      const { CredentialingPage } = await import('./page');
      return { element: <CredentialingPage /> };
    },
  },
  CredentialingPacketRoutes,
];
