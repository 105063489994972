import { RouteObject } from 'react-router-dom';

import { PacketRoutes } from './$requestId/routes';

export const FacilityRoutes: RouteObject[] = [
  {
    path: 'facility',
    lazy: async () => {
      const { CredentialingPage } = await import('./page');
      return { element: <CredentialingPage /> };
    },
  },
  PacketRoutes,
];
