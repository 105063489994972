import { Navigate, RouteObject } from 'react-router-dom';

export const CreateFacilityRoutes: RouteObject = {
  path: 'create/:newFacilityId',
  lazy: async () => {
    const { CreateFacilityLayout } = await import('./$newFacilityId/layout');
    return { element: <CreateFacilityLayout /> };
  },
  children: [
    {
      index: true,
      element: <Navigate to="facility-info" replace />,
    },
    {
      path: 'facility-info',
      lazy: async () => {
        const { FacilityInfo } = await import(
          './$newFacilityId/facility-info/page'
        );
        return { element: <FacilityInfo /> };
      },
    },
    {
      path: 'facility-ids',
      lazy: async () => {
        const { FacilityIds } = await import(
          './$newFacilityId/facility-ids/page'
        );
        return { element: <FacilityIds /> };
      },
    },
    {
      path: 'specialties',
      lazy: async () => {
        const { SpecialtiesPage } = await import(
          './$newFacilityId/specialties/page'
        );
        return { element: <SpecialtiesPage /> };
      },
    },
    {
      path: 'certification',
      lazy: async () => {
        const { Certification } = await import(
          './$newFacilityId/certification/page'
        );
        return { element: <Certification /> };
      },
    },
    {
      path: 'accreditation',
      lazy: async () => {
        const { Accreditation } = await import(
          './$newFacilityId/accreditation/page'
        );
        return { element: <Accreditation /> };
      },
    },
    {
      path: 'insurance',
      lazy: async () => {
        const { Insurance } = await import('./$newFacilityId/insurance/page');
        return { element: <Insurance /> };
      },
    },
    {
      path: 'documents',
      lazy: async () => {
        const { DocumentsPage } = await import(
          './$newFacilityId/documents/page'
        );
        return { element: <DocumentsPage /> };
      },
    },
    {
      path: 'attestation',
      lazy: async () => {
        const { Attestation } = await import(
          './$newFacilityId/attestation/page'
        );
        return { element: <Attestation /> };
      },
    },
  ],
};
