import { Navigate, RouteObject } from 'react-router-dom';

export const FacilityProfileRoutes: RouteObject = {
  path: 'profile',
  lazy: async () => {
    const { FacilityProfileLayout } = await import('./layout');
    return { element: <FacilityProfileLayout /> };
  },
  children: [
    {
      index: true,
      element: <Navigate to="summary" replace />,
    },
    {
      path: 'summary',
      lazy: async () => {
        const { FacilityProfileSummaryPage } = await import('./summary/page');
        return { element: <FacilityProfileSummaryPage /> };
      },
    },
    {
      path: 'accreditation',
      lazy: async () => {
        const { FacilityAccreditationPage } = await import(
          './accreditation/page'
        );
        return { element: <FacilityAccreditationPage /> };
      },
    },
    {
      path: 'certification',
      lazy: async () => {
        const { FacilityCertificationPage } = await import(
          './certification/page'
        );
        return { element: <FacilityCertificationPage /> };
      },
    },
    {
      path: 'documents',
      lazy: async () => {
        const { FacilityDocumentsPage } = await import('./documents/page');
        return { element: <FacilityDocumentsPage /> };
      },
    },
    {
      path: 'facility-ids',
      lazy: async () => {
        const { FacilityIdsPage } = await import('./facility-ids/page');
        return { element: <FacilityIdsPage /> };
      },
    },
    {
      path: 'facility-info',
      lazy: async () => {
        const { FacilityInfoPage } = await import('./facility-info/page');
        return { element: <FacilityInfoPage /> };
      },
    },
    {
      path: 'insurance',
      lazy: async () => {
        const { FacilityInsurancePage } = await import('./insurance/page');
        return { element: <FacilityInsurancePage /> };
      },
    },
    {
      path: 'specialties',
      lazy: async () => {
        const { FacilitySpecialtiesPage } = await import('./specialties/page');
        return { element: <FacilitySpecialtiesPage /> };
      },
    },
  ],
};
