import { RouteObject } from 'react-router-dom';

import { ProviderPacketRoutes } from './$requestId/routes';

export const ProviderRoutes: RouteObject[] = [
  {
    path: 'provider',
    lazy: async () => {
      const { AdminCredentialingPage } = await import('./page');
      return { element: <AdminCredentialingPage /> };
    },
  },
  ProviderPacketRoutes,
];
