import { useQuery } from '@tanstack/react-query';

import { SortOrder } from '@/components/table/sort-order';

import { $api, fetchClient } from '@/lib/api/client';
import { formatPersonName } from '@/lib/string-utils';
import { UserType, userTypeConfig } from '@/lib/user/type';

// TODO: Consider setting a stale time for the query
export function useGetUserDetails({ userId }: { userId?: string | null }) {
  return $api.useQuery(
    'get',
    '/api/v1/users/user-details/{id}/',
    {
      params: {
        path: {
          id: `${userId}`,
        },
      },
    },
    {
      enabled: !!userId,
    },
  );
}

function getOrdering(
  sortOn: string | null | undefined,
  sortOrder: SortOrder | null | undefined,
): string | undefined {
  if (sortOn) {
    if (sortOrder === 'ascend') {
      return `-${sortOn}`;
    } else if (sortOrder === 'descend') {
      return sortOn;
    }
  }
}

export function useGetUserListQuery(options: {
  status: 'active' | 'deactivated' | 'invited';
  limit?: number;
  offset?: number;
  search?: string;
  role?: UserType;
  sortOn?: string;
  sortOrder?: SortOrder;
}) {
  return $api.useQuery('get', '/api/v1/users/user-list/', {
    params: {
      query: {
        is_active: options.status === 'active' ? true : undefined,
        users_deactivated: options.status === 'deactivated' ? true : undefined,
        limit: options.limit,
        offset: options.offset,
        search: options.search,
        user_type: options.role
          ? userTypeConfig[options.role].dtoModel
          : undefined,
        ordering: getOrdering(options.sortOn, options.sortOrder),
      },
    },
  });
}

export function useSuperadminGetUserListQuery(options: {
  status: 'active' | 'deactivated' | 'invited';
  limit?: number;
  offset?: number;
  search?: string;
  role?: UserType;
  sortOn?: string;
  sortOrder?: SortOrder;
}) {
  return $api.useQuery('get', '/api/v1/users/internal_user-list/', {
    params: {
      query: {
        is_active: options.status === 'active' ? true : undefined,
        users_deactivated: options.status === 'deactivated' ? true : undefined,
        limit: options.limit,
        offset: options.offset,
        search: options.search,
        user_type: options.role
          ? userTypeConfig[options.role].dtoModel
          : undefined,
        ordering: getOrdering(options.sortOn, options.sortOrder),
      },
    },
  });
}

export function useGetUserOptionsQuery(options?: {
  userType?: UserType;
  includeSelf?: boolean;
}) {
  return useQuery({
    queryKey: [
      'get',
      '/api/v1/users/user-list-slim/',
      {
        params: {
          query: {
            user_type: options?.userType
              ? userTypeConfig[options.userType].dtoModel
              : undefined,
            include_self: options?.includeSelf,
          },
        },
      },
      'user-options',
    ],
    queryFn: () =>
      fetchClient.GET('/api/v1/users/user-list-slim/', {
        params: {
          query: {
            user_type: options?.userType
              ? userTypeConfig[options.userType].dtoModel
              : undefined,
            include_self: options?.includeSelf,
          },
        },
      }),
    select(data): { value: string; label: string }[] | undefined {
      if (!data.data) {
        return;
      }

      return data.data.results.map((user) => ({
        value: user.id,
        label:
          formatPersonName(user.first_name, null, user.last_name) ?? user.email,
      }));
    },
  });
}
