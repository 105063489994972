import { Navigate, RouteObject } from 'react-router-dom';

import { ProviderProfileRoutes } from './profile/routes';

export const ProviderDetailsRoutes: RouteObject = {
  path: ':userId',
  lazy: async () => {
    const { ProviderDetailsLayout } = await import('./layout');
    return { element: <ProviderDetailsLayout /> };
  },
  children: [
    {
      index: true,
      element: <Navigate to="overview" replace />,
    },
    {
      path: 'overview',
      lazy: async () => {
        const { OverviewPage } = await import('./overview/page');
        return { element: <OverviewPage /> };
      },
    },
    {
      path: 'tasks',
      lazy: async () => {
        const { TasksPage } = await import('./tasks/page');
        return { element: <TasksPage /> };
      },
    },
    ProviderProfileRoutes,
    {
      path: 'monitoring',
      lazy: async () => {
        const { OngoingMonitoringLayout } = await import(
          './ongoing-monitoring/layout'
        );
        return { element: <OngoingMonitoringLayout /> };
      },
      children: [
        {
          index: true,
          element: <Navigate to="overview" replace />,
        },
        {
          path: 'overview',
          lazy: async () => {
            const { OngoingMonitoringOverviewPage } = await import(
              './ongoing-monitoring/overview/page'
            );
            return { element: <OngoingMonitoringOverviewPage /> };
          },
        },
        {
          path: ':eventType',
          lazy: async () => {
            const { VerificationPage } = await import(
              './ongoing-monitoring/$eventType/page'
            );
            return { element: <VerificationPage /> };
          },
        },
      ],
    },
    {
      path: 'practice-locations',
      lazy: async () => {
        const { PracticeLocationsPage } = await import(
          './practice-locations/page'
        );
        return { element: <PracticeLocationsPage /> };
      },
    },
  ],
};
